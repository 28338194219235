<template>
  <!-- 通知公告详情 -->
  <div class="main-contain">
    <div class="maintitle">
      {{ infolist.title }}
    </div>
     <div class="timecontain">
      <div class="time">
        <span>发布时间:</span>
        {{ infolist.updated_at }}</div>
    </div>
    <div class="tips">
      {{ infolist.sub_title }}
    </div>
    <div class="maintext">
      <div v-html="infolist.content"></div>
    </div>
    <div class="appendix">
      <span class="text1">附件:</span>
      <span class="text2" @click="download">{{ infolist.file }}</span>
    </div>
  </div>
</template>
<script>
import { getNoticeInfo } from "@/api/notice";
import { mapState, mapMutations } from "vuex";
import dayjs from "dayjs";
export default {
  name: "courselearning",
  components: {},
  computed: {
    ...mapState(["noticeFlag"]),
  },
  data() {
    return {
      infolist: [],
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/时间 钟表.png"),
    };
  },
  created() {
    this.getnoticeinfo({
      id: parseInt(this.$route.query.id),
    });
  },
  methods: {
    backbt() {
      // this.$router.push({
      //   path: "/home/announcement/mainpage",
      // });
      // 首页点击进来的-返回首页
      // 列表点击进来的-返回列表
      this.$router.back();
    },
  // 下载
    download() {
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = this.infolist.file;
      a.setAttribute("download", this.filename);
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    getnoticeinfo(params) {
      getNoticeInfo(params)
        .then((response) => {
          if (response.data) {
            this.infolist = {
              content: response.data.content,
              id: response.data.id,
              sub_title: response.data.sub_title,
              title: response.data.title,
              file: response.data.file,
              updated_at: dayjs
                .unix(response.data.updated_at)
                .format("YYYY-MM-DD HH:mm:ss"),
            };
          }
        })
        .catch((error) => {
        });
    },
    ...mapMutations(["changeNoticeFlag"]),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.changeNoticeFlag(false);
    });
  },
  beforeRouteLeave(to, from, next) {
    this.changeNoticeFlag(true);
    next();
  },
};
</script>
<style scoped lang="scss">

.main-contain {
  margin-bottom: 20px;
  .maintitle {
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #000000;
    user-select: none;
  }
  .tips {
    box-sizing: border-box;
    padding: 20px;
    padding-left: 40px;
    background: #f7f7f7;
    font-size: 15px;
    font-weight: 400;
    color: #666666;
  }

  .maintext {
    padding: 20px;
    padding-left: 40px;
    font-size: 15px;
    font-weight: 400;
    color: #666666;
  }
  .appendix {
    padding-left: 40px;
    .text1 {
      font-size: 15px;
      font-weight: 400;
      color: #666666;
    }
    .text2 {
      margin-left: 10px;
      font-size: 15px;
      font-weight: 400;
      color: #3d84ff;
      cursor: pointer;
    }
  }
  .timecontain {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .time {
      margin-left: 5px;
      font-size: 14px;
      font-weight: 400;
      color: #999;
      padding-bottom: 10px;

    }
  }
}
</style>
